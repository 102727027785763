import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/style.css";

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        abstract
        authors
        avatars
        descriptions
        urls
        featured {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
      html
      fields {
        slug
      }
    }
  }
`;

const GovernanceDocument = props => {
  return (
    <Layout>
      <Metadata
        title={props.data.markdownRemark.frontmatter.title}
        description={props.data.markdownRemark.frontmatter.abstract}
        author={props.data.markdownRemark.frontmatter.authors[0]}
      />
      <section className="content" role="main">
        <Row className={`imageBox no-margin`} style={{ textAlign: "center" }}>
          {props.data.markdownRemark.frontmatter.featured && (
            <Img
              fluid={
                props.data.markdownRemark.frontmatter.featured.childImageSharp
                  .fluid
              }
              alt={props.data.markdownRemark.frontmatter.title}
            />
          )}
        </Row>
        <Row className={`presentation no-margin`}>
          <h1 className={"mt-2em"} style={{ marginBottom: "0px" }}>
            {props.data.markdownRemark.frontmatter.title}
          </h1>
          <Col className={"justify"} style={{ textAlign: "center" }}>
            <span>
              Last updated {props.data.markdownRemark.frontmatter.date}{" "}
            </span>
          </Col>
        </Row>
        <Row className={`no-margin mt-2em mb-2em`}>
          <div
            dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
            className={"presentation justify"}
          ></div>
        </Row>
      </section>
    </Layout>
  );
};

export default GovernanceDocument;
